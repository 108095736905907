<template>
  <div class="components-form-advanced-search">
    <!-- search start -->
    <a-form class="ant-advanced-search-form" :form="searchForm">
      <a-row :gutter="24">
        <a-col :span="6" style="display: block">
          <a-form-item label="车主">
            <a-input placeholder="车主" v-model="searchForm.carOwner" />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24" :style="{ textAlign: 'right' }">
          <a-button type="primary" @click="searchClick"> 查询 </a-button>
          <a-button :style="{ marginLeft: '8px' }" @click="cleanSearchClick">
            清除
          </a-button>
          <a
            :style="{ marginLeft: '8px', fontSize: '12px' }"
            @click="expand = !expand"
          >
            {{ expand ? "收起" : "展开" }}
            <a-icon :type="expand ? 'up' : 'down'" />
          </a>
        </a-col>
      </a-row>
    </a-form>

    <!-- search end -->
    <!-- table start -->
    <div class="app-card">
      <!-- table 控制 start -->
      <a-row style="padding-bottom: 16px" type="flex" justify="end">
        <a-col>
          <a-button-group>
            <a-tooltip placement="top">
              <template slot="title">
                <span>密度</span>
              </template>
              <a-dropdown :trigger="['click']">
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="column-height"
                />
                <a-menu slot="overlay">
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'default'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'default'"
                      >默认</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'middle'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'middle'"
                      >中等</a
                    >
                  </a-menu-item>
                  <a-menu-item>
                    <a
                      href="javascript:;"
                      :class="
                        tableTdStyle === 'small'
                          ? 'ant-table-td-style-selected'
                          : ''
                      "
                      @click="tableTdStyle = 'small'"
                      >紧凑</a
                    >
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>全屏</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                :icon="isFullscreen ? 'fullscreen-exit' : 'fullscreen'"
                @click="
                  () => {
                    screenfull.toggle();
                    isFullscreen = !isFullscreen;
                  }
                "
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>刷新</span>
              </template>
              <a-button
                style="color: rgba(0, 0, 0, 0.65)"
                type="link"
                icon="reload"
                @click="searchClick"
              />
            </a-tooltip>
            <a-tooltip placement="top">
              <template slot="title">
                <span>列设置</span>
              </template>
              <a-popover trigger="click" placement="bottom">
                <template slot="content">
                  <a-checkbox-group
                    :value="showColumns"
                    @change="changeColumns"
                  >
                    <a-row>
                      <a-col v-for="it of baseColumns" :key="it">
                        <a-checkbox :value="it">
                          {{ it }}
                        </a-checkbox>
                      </a-col>
                    </a-row>
                  </a-checkbox-group>
                </template>
                <a-button
                  style="color: rgba(0, 0, 0, 0.65)"
                  type="link"
                  icon="setting"
                />
              </a-popover>
            </a-tooltip>
          </a-button-group>
        </a-col>
      </a-row>
      <div style="text-align: center;padding-top: 10px;">
        <a-radio-group v-model="tableMode" :style="{ marginBottom: '8px' }" @change="changeTableModel">
          <a-radio-button value="Await">待报价</a-radio-button>
          <a-radio-button value="Done">已报价</a-radio-button>
        </a-radio-group>
      </div>
      <!-- table 控制 end -->
      <a-table
        :columns="columns"
        :rowKey="(record) => record.policyNo"
        :dataSource="tableData"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 1300 }"
        @change="handleTableChange"
        bordered
        :size="tableTdStyle"
      >
        <template #createdAt="createdAt">
          {{ createdAt ? moment(createdAt).format("YYYY-MM-DD HH:mm:ss") : '无' }}
        </template>
        <template #idCardImage="idCardImage">
          <a-tag color="cyan" v-if="idCardImage" @click="handlePreview(idCardImage)">
            点击查看
          </a-tag>
        </template>
        <template #vehicleLicenseImage="vehicleLicenseImage">
          <a-tag color="blue" v-if="vehicleLicenseImage" @click="handlePreview(vehicleLicenseImage)">
            点击查看
          </a-tag>
        </template>
        <template #action="data">
          <a-popconfirm
            v-if="
              $root.$data.privateState.role === 'SuperAdmin' ||
              $root.$data.privateState.action.includes('/CarPolicyInquiry/Quotation')
            "
            title="是否确认认领订单并开始报价?"
            ok-text="是"
            cancel-text="否"
            @confirm="confirmQuotation(data)"
          >
            <a-tag color="blue">立即报价</a-tag>
          </a-popconfirm>
          <div style="padding-top:10px" v-if="$root.$data.privateState.userInfo.uuid === data.lockedByUser && data.locked">
            <a-tag @click="handleUnlockOrder(data)" color="orange">解除锁定</a-tag>
          </div>
        </template>
      </a-table>
    </div>
    <!-- table end -->
    <a-modal
      :visible="sending"
      :footer="null"
      :maskClosable="false"
      :keyboard="false"
      :closable="false"
    >
      <div style="text-align: center">
        <div>{{ sendingHint }}</div>
      </div>
    </a-modal>
    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
    <a-modal :visible="quotationShow" @cancel="quotationShow = false" :closable="false" @ok="quotationOk">
      <a-textarea
        v-model="quotationMessage"
        placeholder="填写报价内容"
        :auto-size="{ minRows: 3, maxRows: 5 }"
      />
    </a-modal>
  </div>
</template>

<script>
import licia from "licia";
import moment from "moment";
import screenfull from "screenfull";
// import DownloadJS from 'downloadjs'
import { CarPolicyInquiry } from "@/api";
import { aliveVM, mixinTableList } from "@/utils/mixin";

const INIT_SEARCH_FORM = '{"carOwner":""}';
const columns = [
  {
    title: "车主",
    width: 150,
    dataIndex: "carOwner",
  },
  {
    title: "身份证图片",
    width: 150,
    dataIndex: "idCardImage",
    scopedSlots: { customRender: "idCardImage" },
  },
  {
    title: "行车证图片",
    width: 150,
    dataIndex: "vehicleLicenseImage",
    scopedSlots: { customRender: "vehicleLicenseImage" },
  },
  {
    title: "询价需求",
    dataIndex: "demand",
  },
  {
    title: "报价结果",
    width: 150,
    dataIndex: "resultsMessage",
  },
  {
    title: "订单时间",
    width: 150,
    dataIndex: "createdAt",
    scopedSlots: { customRender: "createdAt" },
  },
  {
    title: "操作",
    key: "operation",
    width: 130,
    fixed: "right",
    scopedSlots: { customRender: "action" },
  },
];
export default {
  name: "CarPolicyInquiry",
  mixins: [aliveVM, mixinTableList],
  created() {
    const baseColumns = [];
    for (const it of columns) {
      baseColumns.push(it.title);
    }
    this.$set(this, "baseColumns", baseColumns);
    this.fetch();
  },
  computed: {
    showColumns() {
      let c = [];
      for (const it of this.columns) {
        c.push(it.title);
      }
      return c;
    },
  },
  data() {
    return {
      sending: false,
      sendingHint: "正在处理",
      moment,
      screenfull,
      isFullscreen: false,
      expand: false,
      tableListApi: `${CarPolicyInquiry}/TableList?type=Await`,
      searchParams: null,
      searchForm: JSON.parse(INIT_SEARCH_FORM),
      baseColumns: [],
      columns,
      // 其他
      previewVisible: false,
      previewImage: '',
      tableMode: 'Await',
      showDetailModal: false,
      showDetailData: null,
      // 报价
      nowEditOrderUuid: '',
      quotationShow: false,
      quotationMessage: '',
      // 导入
      uploadList: [],
      fileUrl: '',
      fileUploadHeaders: {},
    };
  },
  methods: {
    changeColumns(v) {
      const c = [];
      for (const it of columns) {
        if (v.includes(it.title)) {
          c.push(it);
        }
      }
      this.$set(this, "columns", c);
    },
    async reload() {
      this.$set(this, "searchParams", null);
      this.searchForm.resetFields();
      await this.fetch();
    },
    async cleanSearchClick() {
      this.$set(this, "searchParams", null);
      this.$set(this, "searchForm", JSON.parse(INIT_SEARCH_FORM));
      await this.fetch();
    },
    async searchClick() {
      this.pagination.current = 1;
      const searchForm = JSON.parse(JSON.stringify(this.searchForm));
      const searchParams = {};
      for (const k of Object.keys(searchForm)) {
        const it = searchForm[k];
        if (k === 'PayTime' || k === 'CreatedAt') {
          if (!it || it.length < 1) continue
          it[0] = moment(it[0]).format('YYYY-MM-DD') + " 00:00:00"
          it[1] = moment(it[1]).format('YYYY-MM-DD') + " 23:59:59"
          searchParams[k] = it
        }
        if (licia.isNum(it)) {
          searchParams[k] = it;
          continue;
        }
        if (!licia.isEmpty(it)) {
          searchParams[k] = it;
        }
      }
      this.$set(this, "searchParams", searchParams);
      this.fetch();
    },
    // other
    // 其他
    async confirmQuotation(data) {
      const lock = await this.handleLockOrder(data)
      if (!lock) {
        this.$message.error("进入订单编辑锁定失败，可能他人正在操作该订单")
        return
      }
      this.nowEditOrderUuid = data.uuid
      this.quotationShow = true
    },
    async quotationOk() {
      if (!this.quotationMessage) {
        this.$message.error("请填写询价内容")
        return
      }
      this.sending = true
      const res = await this.$axios.post(`${CarPolicyInquiry}/Quotation?uuid=${this.nowEditOrderUuid}`, {
        message: this.quotationMessage
      })
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      this.sending = false
      if (!res.status) {
        this.$message.error(res.message)
        return
      }
      this.quotationShow = false
      this.fetch();
    },
    changeTableModel() {
      //this.searchForm.sendStatus
      this.tableListApi = `${CarPolicyInquiry}/TableList?type=${this.tableMode}`
      this.searchClick()
    },
    handlePreview(imagePath) {
      this.previewImage = `${this.$appBaseUrl}${imagePath}`;
      this.previewVisible = true;
    },
    async handleLockOrder(data) {
      const res = await this.$axios
        .put(`${CarPolicyInquiry}/LockOrder`, {
          uuid: data.uuid,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return false;
      }
      data.locked = true;
      data.lockedByUser = this.$root.$data.privateState.userInfo.uuid;
      return true;
    },
    async handleUnlockOrder(data) {
      const res = await this.$axios
        .put(`${CarPolicyInquiry}/UnlockOrder`, {
          uuid: data.uuid,
        })
        .catch((e) => {
          return {
            status: false,
            message: e.message.indexOf("401") > -1 ? "登录超时" : e.message,
          };
        });
      if (!res.status) {
        this.$message.error(res.message);
        return;
      }
      this.addFormModalShow = false;
      this.$message.success("操作成功");
    },
  },
};
</script>

<style>
</style>